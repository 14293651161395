.footer {
    padding: 5rem;
    padding-bottom: 5rem;
    min-height: 50vh;
    display: grid;
    background-color: $colorBlack;
    color: rgb(154, 154, 154);
    grid-template-columns: 1fr repeat(2, 30vw) 1fr;
    grid-template-rows: 1fr 2fr min-content;
    list-style-type: none;
    gap:5% 5%;
    text-align: center;
    line-height: 1.3;
    font-family: $bodyFont;
    font-size: 1.5rem;
    position: relative;

    @media screen and (max-width: 50em){
        height: 100vh;
        grid-template-columns: 1fr;
        grid-template-rows: 20vh max-content 1fr;
        // overflow: hidden;
    }


    &__colTitle {
        margin-bottom: 3rem;
        padding-bottom: 5px;
        border-bottom: 1px solid white;
        display: inline-block;
        font-family: $headingFont;
        text-transform: capitalize;
        color: $colorWhite;

    }

    &__logo {
        margin-bottom: 2rem;
        opacity: .6;
    }

    &__branding {
        grid-column: 2/4;
        line-height: 1;
        font-size: 2rem;
        padding: 5rem 0;
        border-bottom: 1px solid rgb(21, 21, 21); 

        @media screen and (max-width: 50em){
            grid-column: 1;
        }
    }

    &__contact {
        list-style-type: none;
        grid-column: 2/4;
        grid-row: 2;

        @media screen and (max-width: 50em){
            grid-column: 1;
            grid-row: 2;
            padding: 5rem;
            max-height: 100%;
        }

        & > li {
            margin-bottom: 1.5rem;
        }

        a:link, a:visited {
            color: inherit;
            font-weight: 400;
        }
    }

    

    &__heading {
        font-family: $headingFont;
        color: $colorWhite;
        text-transform: uppercase;
    }


    
}