
//assets
@import "./assets/variables";
@import "./assets/animations";


//Import fonts
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville|Raleway:400,600,700&display=swap');


//components
@import "./components/nav";
@import "./components/banner";
@import "./components/btn";

//layout
@import "./layout/content";
@import "./layout/copyright";
@import "./layout/footer";
@import "./components/gallery";
@import "./layout/layout";

// base
@import "./base/base";



