.banner {
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $colorWhite;
    overflow: hidden;
    animation: fade-in 1s .1s;
    background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.2)), url("/images/bg_2600w.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    

    @media screen and (max-width: 70em){
        height: 80vh;
    }

    @media screen and (max-width: 50em){
        height: 100vh;
        top: 0;
        bottom: 0;
        z-index: -100;
        padding: 2rem;
    }


    &__cont {
        text-align: center;
        

        @media screen and (max-width: 27em){
            transform: translateY(0%);
        }
    }

    
    &__heading {
        font-size: 9vh;
        // text-transform: uppercase;

        @media screen and (max-width: 70em){
            font-size: 7vh;
        }

        @media screen and (max-width: 50em){
            font-size: 6vh;
        }
    }

    &__sub {
        font-size: 3vh;
        text-transform: lowercase;
        margin-top: 2rem;

        @media screen and (max-width: 70em){
            font-size: 2vh;
        }

        @media screen and (max-width: 50em){
            margin-top: 1rem;
        }
    }
}