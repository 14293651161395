.copyright {
    
    text-align: center;
    width: 100%;
    color: #666;
    font-size: 1.2rem;
    grid-row: 3;
    grid-column: 2/4;
    margin: 2rem 0;

    @media screen and (max-width: 50em){
        grid-column: 1;
        align-self: flex-end;
    }

    a {
        color: inherit;
    }

    a:hover {
        color: $colorWhite;
    }

}