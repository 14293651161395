* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: $bodyFont;
}

body {
    padding: 7rem;
    padding-top: 2rem;
    font-size: $defaultFontSize;

    @media screen and (max-width: 50em){
        padding: 0;
    }
    
}

h1, h2, h3, h4, h5 {
    font-family: $headingFont;
    font-weight: normal;
    // text-transform: uppercase;
}

p {
    margin-bottom: 2rem;
    color: $bodyTextColor;
}

::selection {
    background-color: rgba(157, 55, 0, 0.927);
}