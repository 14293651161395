// COLOURS
$colorBlack: #000000;
$colorWhite: #f7f7f7;

$bodyTextColor: #555555;

// FONTS
$headingFont: 'Libre Baskerville', serif;
$bodyFont: 'Raleway', serif;
$defaultFontSize: 1.8rem;

// SIZES
$siteWidth: 138rem;

//TEXT STYLES
$textTrans: lowercase;

//BORDERS
$bottomBorder: 2px solid $colorBlack;