.content {
    margin: 0 auto;
    padding: 20vh 10rem;
    display: grid;
    background-color: $colorWhite;
    grid-template-columns: repeat(6,  1fr);
    min-height: 80vh;
    font-size: 2rem;
    gap: 1rem;
    line-height: 1.5;

    @media screen and (max-width: 70em){
        padding: 10vh 10rem;
        min-height: unset;
    }

    @media screen and (max-width: 50em){
        padding: 10vh 7rem;
    }

    @media screen and (max-width: 27em){
        padding: 10vh 2rem;
        position: relative;
        
    }

    &__heading {
        font-size: 5vh;
        grid-row: 1;
        grid-column: 4 / 6;
        // text-align: left;
        text-transform: capitalize;
        margin-bottom: 4vh;
        line-height: 1.3;
        border-bottom: $bottomBorder;

        @media screen and (max-width: 94em){
            grid-column: 4 / 7;
        }

        @media screen and (max-width: 70em){
            grid-column: 1 / 6;
            
        }
    }

    &__cont {
        grid-column: 4/6;
        grid-row: 2;
        margin-bottom: 20vh;

        @media screen and (max-width: 94em){
            grid-column: 4 / 7;
        }

        @media screen and (max-width: 70em){
            grid-column: 1 / 7;
            grid-row: 3;
            margin-bottom: 10vh;
        }

        & > p {
            margin-bottom: 2rem;
        } 
    }

    &__img {
        grid-row: 1 / 3;
        border-radius: 50%;
        width: 20vw;
        height: 20vw;
        object-fit: cover;
        grid-column: 2/4;
        justify-self: center;
        justify-self: start;
        margin-top: 3rem;

        @media screen and (max-width: 94em){
            grid-column: 1 / 4;
            width: 30vw;
            height: 30vw;
            justify-self: start;
        }

        @media screen and (max-width: 70em){
            grid-column: 1 / 7;
            grid-row: 2;
            margin-bottom: 5rem;
            width: 100%;
            height: auto;
            justify-self: center;
            border-radius: unset;
        }
        
    }
}