.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: min-content 1fr;
    gap: 2rem;
    grid-column: 1/7;
    margin-bottom: 5rem;
    align-content: center;
    justify-content: center;

    @media screen and (max-width: 27em){
        grid-template-columns: 1fr;
    }

    &__title {
        grid-row: 1;
        grid-column: 1 /-1;
        color: $colorBlack;
        font-size: 5vh;
        border-bottom: $bottomBorder;
        line-height: 2;
        margin-bottom: 2rem;
    }

    & > img {
        max-width: 100%;
        width: 100%;
        height: 25vw;
        object-fit: cover;
        filter: grayscale(60%);

        @media screen and (max-width: 27em){
            height: 90vw;
        }
    }
}