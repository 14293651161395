.btn {
    padding: 1rem 2rem;
    color: $colorWhite;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 2rem;
    transition: all 0.3s ease;
    font-family: $headingFont;
    font-size: 1.5rem;


    &--dark {
        background-color: #333;
    }

    &--center {
        text-align: center;
        grid-column: 2 / 3;

        @media screen and (max-width: 50em){
           grid-column: unset;
        }
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 1rem 1rem rgba(0,0,0,0.1);
    }

}