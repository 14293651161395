.nav {
    color: $colorBlack;
    display: flex;
    align-items: flex-end;
    padding: 2rem 0;
    font-size: 1.6rem;

    @media screen and (max-width: 50em){
        padding: 2rem;
    }

    @media screen and (max-width: 50em){
        flex-direction: column;
        position: absolute;
        text-align: center;
        width: 100%;
        color: $colorWhite;
    }

    

    &__title {
        font-size: 2.2rem;
        animation: slide-in-left 1s  .1s;
        font-weight: bold;
        text-transform: uppercase;

        @media screen and (max-width: 50em){
            animation: slide-in-top 1s  .1s;
        }

        @media screen and (max-width: 27em){
            font-size: 1.3rem;

        }
    }

    &__info {
        // font-weight: bold;
        list-style-type: none;
        display: flex;
        margin-left: auto;
        font-family: $bodyFont;
        text-transform: uppercase;
        
        @media screen and (max-width: 50em){
            display: none;
        }
    }

    &__item {
        display: inline;
        margin-left: 2rem;
    }

    &__link, &:visited{
            
            text-decoration: none;
            color: $colorBlack;
            display: inline-block;
            border-bottom: 2px solid white;
            transition: all 0.3s ease;
        

        &:hover {
            border-bottom: 2px solid black;
        }
    }
}